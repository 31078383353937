@import '../../scss/_web_base';

.reportCommonSelectionWrapper{
    font-size: 12px !important;
    color: #5d6d7b !important;
    position: relative;
    .noWrapStyle{
      white-space: nowrap;
    }
    .operationBtn{
      border-radius: 5px;
      padding: 7px 15px;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;

      &.dateSelectBtn{
        border: 1px solid #DCDFE6;
        &:hover,&.selected{
        color: #409EFF;
        border-color: #c6e2ff;
        background-color: #ecf5ff;
        }
      }

      &.findAndCollapse{
        color: #FFF;
          @include themify($themes){
              background: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          &:hover{
              @include themify($themes){
                  background: themed(theme-secondary-color);
                  border-color:themed(theme-secondary-color);
                }
          }
      }

      &.cancel{
        color: #FFF;
          background-color: #F56C6C;
          border-color: #F56C6C;
          &:hover{
              background: #f78989 !important;
              border-color: #f78989 !important;
          }
      }
    }

    .reportCommonSelectionTableWrapper{
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin: 10px 0;
      border-radius: 5px;
      border: 1px solid #ddd;
      background: #ffffff;

      .combineTitleCell{
          height: 100%;
        &>div:last-child{
            border-top: 1px solid #ddd;
            &>div{
                flex: 1;
            }
            &>div:not(:last-child){
                border-right: 1px solid #ddd;
            }
        }
    }
    .combineBodyRowCell{
        height: 100%;
        display: flex;
        &>div{
            height: 100%;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &>div:not(:last-child){
            border-right: 1px solid #ddd;
        }
    }

      .userTable{
        .el-input{
          width: 220px !important;
          margin-left: 5px !important;
        }

        .el-input__inner{
          height: 30px !important;
        }
 
      .el-select-dropdown__item.selected,
      .el-select-dropdown__item.selected.hover {
        font-weight: 700;
        @include themify($themes){
          color: themed(theme-primary-color) !important;
          background-color: themed(drop-down-hover) !important;
        }
      }

      .el-checkbox{
        width: 120px !important;
        margin: 5px 15px !important;
      }

      .el-select {
        .el-input__inner:hover, .el-input__inner:focus {
          @include themify($themes){
            border-color: themed(theme-primary-color) !important;  
            background-color: themed(drop-down-hover) !important;
          }
        }
        .el-input__inner {      
          width: 220px !important;
          padding-right: 10px !important;
          .is-active {
          @include themify($themes){
            color: themed(theme-primary-color) !important;
            border-color:themed(theme-primary-color) !important;
          }
        }
        }   
      }  
      .el-select-dropdown__item:hover {
        @include themify($themes){
          background-color: themed(drop-down-hover) !important;
        }
      }
      .el-select-dropdown {
        border-radius: 4px !important;
      }
        .betQueryDatepicker {
            .el-input {
              width: 250px !important;
            }
          }
        border: 1px solid #ddd;
        &>div{
          min-height: 40px;
        }

        &>div{
            &:not(:first-child){
                border-top: 1px solid #ddd;
            }
          &>div:first-child{
            width: 20%;
            border-right: 1px solid #ddd;
            @include themify($themes){
              background-color: themed(theme-third-color);
            }
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &>div:last-child{
            width: 80%;
            padding-left: 10px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
          }

          .selectedAndUnselected{
            width: 100%;
            margin-left: 15px;
            display: flex;
            &>div{
              height: 22px;
              border-radius: 3px;
              line-height: 20px;
              padding: 0 9px;
              cursor: pointer;
            }
            &>div:first-child{
              background-color: #f96c41;
              color: white;
            }
            &>div:last-child{
              @include themify($themes){
                background-color: themed(theme-primary-color);
              }
              color: white;
            }
          }
        }
      }
      th,td{
        height: 40.5px;
      }
    }
}