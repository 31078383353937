@import '../../../scss/web_base';

.DecreaseOddsSettingWarpper{
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    min-width: 975px;
    width: 100%;
    font-size: 12px;
    .radiobuttongroup{
      *{
        cursor: pointer;
      }
    }
    @include themify($themes){
      font-family: themed(font-family);
    }
    .subselection {
      position:absolute;
      top:5;
      z-index:1;
    }
    .hide {
      display: none;
    }
    .menuWrapper {
      background: white;
      height: 30px;
      width: 120px !important;
      font-size: 12px;
      text-align: left;
      border-radius: 3px;
      padding: 3px 12px;
      @include themify($themes){
        border: 1px solid themed(table-border-color);
        color: themed(theme-primary-color);
      }
    }
      /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .form-control {
      width: 17%;
    }
    .menuSelectedText {
        width: 90px !important;
    }
    .menuactive {
      color:white !important;
      font-weight: 600;
      @include themify($themes){
        border: 1px solid themed(theme-primary-color);
        background: themed(theme-primary-color);
      }
    }
    input[type='number'] {
      height: 26px;
      width: 120px !important;
      font-size: 12px;
      text-align: center;
      border-radius: 3px;
      @include themify($themes){
        border: 1px solid themed(table-border-color);
        color: themed(table-header-text-color);
      }
    }

    /* RADIO STYLE */
    input[type='radio'] {
      width: 15px !important;
      height: 15px !important;
      padding: 0;
      margin: auto;
      vertical-align: middle;
      @include themify($themes){
        color: themed(data-table-text-color);
      }
      .el-radio__label { 
        font-size: 12px;
      }
      .is-checked {
        border-radius: 100%;
        @include themify($themes){
          border:1px solid themed(theme-lighter-color);
          color: themed(theme-lighter-color);
        }
        .el-radio__label { 
          @include themify($themes){
            color: themed(theme-primary-color);
            }
        }
      }
    }
    /* ========== */

    .active {
      @include themify($themes){
        color: themed(theme-lighter-color);
        }
    }
    .form-control {
      width: 17%;
    }
    .subMenu  {
      width: 100%;
      @include themify($themes){
        background-color: themed(theme-third-color);
      }
    }
    .footer {
      width: 100%;
      position: fixed;
      bottom:24px;
      left:0;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      @include themify($themes){
        background-color: themed(theme-third-color);
        color: themed(table-header-text-color);
      }
      input[type='number'], input[type='text'] {
        height: 21px;
        width: 120px !important;
        font-size: 12px;
        text-align: center;
        border-radius: 3px;
        @include themify($themes){
          border: 1px solid themed(table-border-color);
          color: themed(table-header-text-color);
        }
      }
      .saveButton {
        height: 22px;
        padding: 0px 10px;
        border-radius: 3px;
        font-weight: normal;
        @include themify($themes) {
          background: themed(small-button-color);
        }
      }
    }
    .cancelButton {
      color: $white;
      font-weight: 700;
      @include themify($themes) {
        background: themed(search-button-color);
        border: 1px solid themed(search-button-color);
      }
      border-radius: 5px;
      outline: none;
      font-size: 12px;
      position: relative;
      padding: 5px 15px 5px 15px;
      box-shadow: none;
      &:hover {
        @include themify($themes) {
          background: themed(search-button-hover-color);
          border: 1px solid themed(search-button-color);
        }
      }
    }
    .saveButton {
      color: $white;
      font-weight: 700;
      @include themify($themes) {
        background: themed(theme-lighter-color);
        border: 1px solid themed(theme-primary-color);
      }
      border-radius: 5px;
      outline: none;
      font-size: 12px;
      position: relative;
      padding: 5px 15px 5px 15px;
      box-shadow: none;
      &:hover {
        @include themify($themes) {
          background: themed(theme-primary-color);
        }
      }
    }
}