@import '../../../scss/_web_base';

.RankListWrapper{
    font-size: 12px;
    font-size: 12px;
    color: #5d6d7b;
    position: relative;
    .noWrapStyle{
      white-space: nowrap;
    }

    .billQueryTableWrapper{
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin: 10px 0;
      border-radius: 5px;
      border: 1px solid #ddd;
      background: #ffffff;

      .userTable{
          .inputFormStyle{
            width: 210px;
          }
          .operationBtn{
            border-radius: 5px;
            padding: 7px 15px;
            line-height: 1;
            white-space: nowrap;
            cursor: pointer;

            &.dateSelectBtn{
              border: 1px solid #DCDFE6;
              &:hover,&.selected{
              color: #409EFF;
              border-color: #c6e2ff;
              background-color: #ecf5ff;
              }
            }
    
            &.findAndCollapse{
              color: #FFF;
                @include themify($themes){
                    background: themed(theme-primary-color);
                    border-color:themed(theme-primary-color);
                  }
                &:hover{
                    @include themify($themes){
                        background: themed(theme-secondary-color);
                        border-color:themed(theme-secondary-color);
                      }
                }
            }
    
            &.cancel{
              color: #FFF;
                background-color: #F56C6C;
                border-color: #F56C6C;
                &:hover{
                    background: #f78989 !important;
                    border-color: #f78989 !important;
                }
            }
          }
        border: 1px solid #ddd;

      }
      th,td{
        height: 40.5px;
      }
      .tableBodyRow {
        &>td:first-child{
          @include themify($themes){
            background-color: themed(theme-third-color);
          }
        width: 150px !important;
        }
      }
    }
}