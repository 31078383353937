@import '../../scss/web_base';

.autoBuhuoSettingWarpper{
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    width: 100%;
    font-size: 12px;
    .radiobuttongroup{
      *{
        cursor: pointer;
      }
    }
    @include themify($themes){
      font-family: themed(font-family);
    }
    .subselection {
      position:absolute;
      top:5;
      z-index:1;
    }
    .hide {
      display: none;
    }
    .menuWrapper {
      background: white;
      height: 30px;
      width: 120px !important;
      font-size: 12px;
      text-align: left;
      border-radius: 3px;
      padding: 3px 12px;
      @include themify($themes){
        border: 1px solid themed(table-border-color);
        color: themed(theme-primary-color);
      }
    }

    .menuSelectedText {
        width: 90px !important;
    }
    .menuactive {
      color:white !important;
      font-weight: 600;
      @include themify($themes){
        border: 1px solid themed(theme-primary-color);
        background: themed(theme-primary-color);
      }
    }

    .active {
      @include themify($themes){
        color: themed(theme-lighter-color);
        }
    }

    .subMenu  {
      width: 100%;
      @include themify($themes){
        background-color: themed(theme-third-color);
      }
    }
    .footer {
      width: 100%;
      max-height: 60px;
      position: fixed;
      bottom:24px;
      left:0;
      overflow: auto;
      border-top: 1px solid rgba(0, 0, 0, 0.05);
      @include themify($themes){
        background-color: themed(theme-third-color);
        color: themed(table-header-text-color);
      }

      .saveButton {
        height: 22px;
        padding: 0px 10px;
        border-radius: 3px;
        font-weight: normal;
        @include themify($themes) {
          background: themed(small-button-color);
        }
      }
    }
    .cancelButton {
      color: $white;
      font-weight: 700;
      @include themify($themes) {
        background: themed(search-button-color);
        border: 1px solid themed(search-button-color);
      }
      border-radius: 5px;
      outline: none;
      font-size: 12px;
      position: relative;
      padding: 5px 15px 5px 15px;
      box-shadow: none;
      &:hover {
        @include themify($themes) {
          background: themed(search-button-hover-color);
          border: 1px solid themed(search-button-color);
        }
      }
    }
    .saveButton { 
      cursor: pointer;
      color: $white;
      font-weight: 700;
        @include themify($themes) {
          background: themed(theme-primary-color);
        }
      border-radius: 5px;
      outline: none;
      font-size: 12px;
      position: relative;
      padding: 5px 15px 5px 15px;
      box-shadow: none;
    }
}