@import '../../scss/web_base';

    .loginAndRegister{
        width: 100%;
        height: 100%;
        &.blue{
        background-image: linear-gradient(51deg, #63a3ff ,  #b6dcff,#72beff);
        }
        &.red{
            background-image: linear-gradient(51deg,#fa6c84,#f7b7b8,#f0a1a4);
            }
        &.green{
            background-image: linear-gradient(51deg,#91c740,#cde7a5,#cff498);
            }
        &.yellow{
            background-image: linear-gradient(51deg,#ddb358,#f5d99f,#e8c67e);
            }
        display: flex;
        justify-content: center;
        align-items: center;
        .loginNoAccount{
            display: flex;
            padding-left: 20px;
            span{
                @include themify($themes){
                    color: themed(theme-primary-color);
                }
                cursor: pointer;
            }
            &>div{
                flex: 1;
                text-align: end;
                padding-right: 20px;
                cursor: pointer;
            }
        }
        .loginBox{
            width: 446px;
            background-color: white;
            padding: 30px 40px;
            border-radius: 20px;
            .loginTitle{
                @include themify($themes){
                    color: themed(theme-secondary-color);
                }
                font-weight: bold;
                margin-top: 10px;
                margin-bottom: 30px;
            }
            .loginBoxItem{
                margin-bottom: 20px;
                height: 45px;
                border-radius: 30px;
                width: 100%;
                border: 1px solid #ccc;
                display: flex;
                align-items: center;
                .loginButton{
                    @include themify($themes){
                        background-color: themed(theme-primary-color);
                    }
                    &:hover{
                        @include themify($themes){
                            background-color: themed(theme-secondary-color) !important;
                        }
                    }
                    height: 45px !important;
                    width: 100%;
                    border-radius: 30px;
                    border: none !important;
                    font-weight: bold;
                }
                &>div:first-child{
                    font-weight: bold;
                    padding-left: 20px;
                    @include themify($themes){
                        color: themed(theme-primary-color);
                    }
                    width: 30%;
                }
                input{
                    flex: 1;
                    border: none !important;
                    background-color: transparent !important;
                    &:focus{
                        box-shadow: none !important;
                    }
                }
                #captchaImg{
                    height: 45px !important;
                    border-top-right-radius: 30px;
                    border-bottom-right-radius: 30px;
                }
            }
        }

    }
    .loginRegisterWarpper{
        width: 100%;
        height: 100%;
    }