@import '../../../scss/web_base';

.OddsDiffWrapper{
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    min-width: 975px;
    width: 100%;
    @include themify($themes){
      font-family: themed(font-family);
      color: themed(data-table-text-color);
    }
    .hide {
      display: none;
    }
    .marginLeft5{
      margin-left: 2.2em;
    }
    .active {
      @include themify($themes){
        color: themed(theme-lighter-color);
        }
    }
    .button {
      color: $white;
      height: 30px;
      font-weight: 700;
      border-radius: 5px;
      outline: none;
      font-size: 12px;
      position: relative;
      padding: 5px 15px 5px 15px;
      box-shadow: none;
      &.find{
        background: #f56f62;
        border: 1px solid #f56f62;
      }
      &.generate{
        background-color: #E6A23C;
        border-color: #E6A23C;
      }
      &.handle{
        background-color: #409EFF;
        border-color: #409EFF;
      }
      &.copy {
        background-color: #67C23A;
        border-color: #67C23A;
      }
    }
    .el-tabs__item {
      font-size: 12px !important;
      height: auto !important;
    }
    .el-input {
      font-size: 12px !important;
    }
    .lotteryMenuWrapper {
      border: 1px solid rgb(220,220,220);
      border-radius: 3px;
      padding: 8px 20px;
      margin-right: 10px !important;
      @include themify($themes){
        border: 1px solid themed(table-border-color);
        background-color: themed(lottery-menu-color);
      }
    }
    .drawBox {
      @include themify($themes){
        border-left: 1px solid themed(table-border-color);
        border-right: 1px solid themed(table-border-color);
        border-bottom: 1px solid themed(table-border-color);
      }
    }
    .el-tabs__header {
      margin: 0 !important;
    }
    .el-tabs__nav-scroll {
      @include themify($themes){
        border: 1px solid themed(table-border-color);
      }
    }
    .radioButtonGroup {
      .el-radio{
        .el-radio__label { 
          font-size: 12px;
          @include themify($themes){
            color: themed(data-table-text-color);
          }
        } 
      }
      .el-radio__input {
        background:white;
        border-radius: 3px;
        padding:3px;
        height: 20px;
        width: 20px;
        @include themify($themes){
          border: 1px solid themed(table-border-color);
        }
        &.is-checked {
          background-color: #f96c41;
        }
        .el-radio__inner {
          border: none !important;
          background:white;
          margin: 1px;
          height: 10px;
          width: 10px;
        }
        .el-radio__inner::after{
          @include themify($themes){
            background:white;
            border: none;
          }
        }
        .is-checked {
          background-color: red;
          @include themify($themes){
            border:1px solid themed(theme-lighter-color);
            color: themed(theme-lighter-color);
          }
          .el-radio__label { 
            @include themify($themes){
              color: themed(theme-primary-color);
              }
          }
        }
    }
    }
    .bigWrapper {
      box-shadow: 0 1px 2px rgba(0,0,0,0.15);
      transition: box-shadow 0.3s ease-in-out;
      border-radius: 5px;
      border: 1px solid #ddd;
      width: 100%;
    }
    .filterWrapper {
      color: #5d6d7b;
      font-size: 12px;
      border-radius: 3px;
      border: 1px solid #dcdcdc;
        width: 100%;
        @include themify($themes){
          border: 1px solid themed(table-border-color);
          background-color: themed(theme-third-color);
        }
        input[type=text]  {
          height: 30px;
          width: 150px !important;
          padding: 5px 8px 5px 8px;
        }
        input[type=number] {
          font-size: 12px;
          width: 120px;
          height: 30px;
        }
        /* Chrome, Safari, Edge, Opera */
        input::-webkit-outer-spin-button,
        input::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }

        /* Firefox */
        input[type=number] {
          appearance: textfield;
          -moz-appearance: textfield;
        }
        .form-control {
          width: 17%;
        }
        .el-select-dropdown__item.selected,
        .el-select-dropdown__item.selected.hover {
          font-weight: 700;
          @include themify($themes){
            color: themed(theme-primary-color);
            background-color: themed(drop-down-hover);
          }
        }

        .el-select {
          .el-input__inner:hover, .el-input__inner:focus {
            @include themify($themes){
              border-color: themed(theme-primary-color);  
              background-color: themed(drop-down-hover);
            }
          }
          &.small {
            .el-input__inner {      
              width: 70px !important;
            }
          }
          .el-input__inner {      
            width: 135px;
            padding-right: 10px;
            .is-active {
            @include themify($themes){
              color: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          }
          }   
        }  
        .el-select-dropdown__item:hover {
          @include themify($themes){
            background-color: themed(drop-down-hover);
          }
        }
        .el-select-dropdown {
          border-radius: 4px !important;
        }
      }

      /* Dropdown Button */
.dropbtn {
  background-color: #ffffff;
  font-size: 13px;
  border:1px solid #bfcbd9;
  cursor: pointer;
  border-radius: 3px;
  min-width: 160px;
  padding: 5px 10px 5px 10px;
  text-align: left;
  .wlimit {
    min-width: 132px
  }
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #f8f8f8;
  border:1px solid #bfcbd9;
}

/* The search field */
#myInput {
  box-sizing: border-box;
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 13px;
  border: none;
  min-width: 165px;
  border-bottom: 1px solid #ddd;
}

/* The search field when it gets focus/clicked on */
#myInput:focus {outline: 3px solid #ddd;}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  font-size: 13px;
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 3px;
  z-index: 1;
  cursor: pointer;
  .tooverflow {
    max-height: 450px;
    overflow: auto;
    padding: 0 10px 0 10px;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(212, 212, 212);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(204, 204, 204);
    }
  }
  p {
    padding: 5px;
    margin: 0;
  }
  p:hover, .hightlight {
    background-color: rgb(240, 240, 240);
  }

}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}
}