@import '../../../scss/web_base';

.depositWrapper{
    width: 100%;
    padding: 10px;
    .subTitleWrapper{
        font-size: 14px;
        color: #5d6d7b;
        font-weight: bold;
        @include themify($themes){
          background-color: themed(theme-third-color);
        }
        height: 40px;
        padding-left: 10px;
        line-height: 40px;
    }
    .subContentWrapper{
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
        padding: 10px;
        margin: 10px 0;
        border-radius: 5px;
        border: 1px solid #ddd;
        background: #ffffff;
        display: flex;
        flex-wrap: wrap;
        &.NoFlex{
            display: block !important;
        }

        .el-input{
            width: 200px !important;
        }

        .payItem{
            cursor: pointer;
            width: 150px;
            height: 40px;
            border: 1px solid #dcdcdc;
            border-radius: 5px;
            display: flex;
            position: relative;
            justify-content: center;
            align-items: center;
            margin: 0 5px 10px 0;
            font-size: 13px;
            color: #555;
            .topupIcon{
              width: 35px;
              height: 35px;
            }
            &.selected{
                border: 1px solid #448eed;
                color: #448eed;
                &::after{
                  background-image: url('../../../assets//image/TopUpIcons//icon_check.png');
                  content: "";
                  width: 20px;
                  height: 20px;
                  position: absolute;
                  bottom: 0px;
                  right: 0px;
                  background-repeat: no-repeat;
                  background-position: center;
                  background-size: cover;
                }
            }
        }
    }

    .operationBtn{
        width: 150px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 5px;
        padding: 7px 10px;
        // line-height: 1;
        // white-space: nowrap;
        cursor: pointer;
        color: #FFF;
        @include themify($themes){
          background: themed(theme-primary-color);
          border-color:themed(theme-primary-color);
        }
        &.disabled{
          pointer-events: none;
          background: grey;
          border-color:grey;
        }
        &:hover{
            @include themify($themes){
                background: themed(theme-secondary-color);
                border-color:themed(theme-secondary-color);
              }
        }
    
        &.add, &.cancel{
          background-color: #F56C6C;
          border-color: #F56C6C;
          &:hover{
              background: #f78989 !important;
              border-color: #f78989 !important;
          }
          .plus_icon{
            margin-right: 3px;
            border-radius: 50%;
            padding: 0px 2px;
            display: inline;
            background-color: white !important;
          .el-icon-plus{
            color: #F56C6C;
          }
        }
        }
      }
}