@import '../../../scss/_web_base';

.depositWithdrawReportWrapper{
    font-size: 12px;
    font-size: 12px;
    color: #5d6d7b;
    position: relative;
    .noWrapStyle{
      white-space: nowrap;
    }

    .depositWithdrawReportTableWrapper{
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin: 10px 0;
      border-radius: 5px;
      border: 1px solid #ddd;
      background: #ffffff;

      .combineTitleCell{
          height: 100%;
        &>div:last-child{
            border-top: 1px solid #ddd;
            &>div{
                flex: 1;
            }
            &>div:not(:last-child){
                border-right: 1px solid #ddd;
            }
        }
    }
    .combineBodyRowCell{
        height: 100%;
        display: flex;
        &>div{
            height: 100%;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &>div:not(:last-child){
            border-right: 1px solid #ddd;
        }
    }

      .userTable{
        .el-input{
            width: 120px;
            margin-left: 5px;
          }
  
          .el-input__inner{
            height: 30px !important;
          }
   
        .el-select-dropdown__item.selected,
        .el-select-dropdown__item.selected.hover {
          font-weight: 700;
          @include themify($themes){
            color: themed(theme-primary-color);
            background-color: themed(drop-down-hover);
          }
        }

        .el-select {
          .el-input__inner:hover, .el-input__inner:focus {
            @include themify($themes){
              border-color: themed(theme-primary-color);  
              background-color: themed(drop-down-hover);
            }
          }
          .el-input__inner {      
            width: 115px;
            padding-right: 10px;
            .is-active {
            @include themify($themes){
              color: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          }
          }   
        }  
        .el-select-dropdown__item:hover {
          @include themify($themes){
            background-color: themed(drop-down-hover);
          }
        }
        .el-select-dropdown {
          border-radius: 4px !important;
        }
        .betQueryDatepicker {
            .el-input {
              width: 250px !important;
            }
          }
          .operationBtn{
            border-radius: 5px;
            padding: 7px 15px;
            line-height: 1;
            white-space: nowrap;
            cursor: pointer;

            &.dateSelectBtn{
              border: 1px solid #DCDFE6;
              &:hover,&.selected{
              color: #409EFF;
              border-color: #c6e2ff;
              background-color: #ecf5ff;
              }
            }
    
            &.findAndCollapse{
              color: #FFF;
                @include themify($themes){
                    background: themed(theme-primary-color);
                    border-color:themed(theme-primary-color);
                  }
                &:hover{
                    @include themify($themes){
                        background: themed(theme-secondary-color);
                        border-color:themed(theme-secondary-color);
                      }
                }
            }
    
            &.cancel{
              color: #FFF;
                background-color: #F56C6C;
                border-color: #F56C6C;
                &:hover{
                    background: #f78989 !important;
                    border-color: #f78989 !important;
                }
            }
          }
        border: 1px solid #ddd;
        &>div{
          height: 40px;
        }

        &>div{
            &:not(:first-child){
                border-top: 1px solid #ddd;
            }
          &>div:first-child{
            width: 20%;
            border-right: 1px solid #ddd;
            @include themify($themes){
              background-color: themed(theme-third-color);
            }
            display: flex;
            justify-content: center;
            align-items: center;
          }
          &>div:last-child{
            width: 80%;
            padding-left: 10px;
            display: flex;
            align-items: center;
          }
        }
      }
      th,td{
        height: 40.5px;
      }
    }
}