@import '../../scss/web_base';

.InTimeSheetBottomMenuWrapper {
    border-top: 1px solid #dcdcdc;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0px 15px;
    position: fixed;
    bottom: 24px;
    left: 0;
    right: 0;
    height: 45px;
    width: 100%;
    min-width: 1280px;
    @include themify($themes){
        background-color:themed(theme-third-color);
    }
    .button_do{
        cursor: pointer;
        width: 20px;
    }
    .amountSettingBtn{
        cursor: pointer;
        margin-left: 3px;
        border-radius: 3px;
        padding: 0px 8px;
        color: white;
        @include themify($themes){
            background-color: themed(theme-primary-color);
            border: 1px solid themed(theme-primary-color);
        }
        &:hover{
            @include themify($themes){
                background-color: themed(theme-secondary-color);
                border: 1px solid themed(theme-secondary-color);
            }
        }
    }
    .amountInput{
        width: 100px;
        padding-left: 10px;
    }
    .amountItem{
        font-size: 12px;
        cursor: pointer;
        border-radius: 3px;
        padding: 0px 8px;
        margin: 0px 3px;
        @include themify($themes){
            border: 1px solid themed(theme-primary-color);
            color: themed(theme-primary-color);
        }
        &.selected{
            @include themify($themes){
                color: white;
                background-color: themed(theme-primary-color);
            }
        }
    }
    .inTimeSheetCheckBoxGroup{
        margin-right: 30px;
    }
}