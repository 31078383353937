@import '../../scss/web_base';

.walletManagementWrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  position: relative;
  .loginLogWrapper, .handlerLogWrapper, .userProfileWrapper{
    width: 100%;
  }
}