@import '../../../scss/_web_base';

.backIcon{
  cursor: pointer;
}

.memberSummationReportWrapper{
    font-size: 12px;
    color: #5d6d7b;
    position: relative;
    .noWrapStyle{
      white-space: nowrap;
    }

    .memberSummationReportTableWrapper{
      .operationBtn{
        border-radius: 5px;
        padding: 7px 10px;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        color: #FFF;
        @include themify($themes){
          background: themed(theme-primary-color);
          border-color:themed(theme-primary-color);
        }
        &:hover{
            @include themify($themes){
                background: themed(theme-secondary-color);
                border-color:themed(theme-secondary-color);
              }
        }
    
        &.editAndSave{
          @include themify($themes){
              background: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          &:hover{
              @include themify($themes){
                  background: themed(theme-secondary-color);
                  border-color:themed(theme-secondary-color);
                }
          }
      }
      &.cancel, &.editAndSave{
        padding: 10px 15px !important;
      }
    
        &.add, &.cancel,&.serchAndDetail{
          background-color: #F56C6C;
          border-color: #F56C6C;
          &:hover{
              background: #f78989 !important;
              border-color: #f78989 !important;
          }
          .plus_icon{
            margin-right: 3px;
            border-radius: 50%;
            padding: 0px 2px;
            display: inline;
            background-color: white !important;
          .el-icon-plus{
            color: #F56C6C;
          }
        }
        }
      }

      .userTable{
        display: flex;
        padding: 5px 15px;
        align-items: center;
        .el-input{
            width: 120px;
            margin-left: 5px;
          }
  
          .el-input__inner{
            height: 30px !important;
          }
   
        .el-select-dropdown__item.selected,
        .el-select-dropdown__item.selected.hover {
          font-weight: 700;
          @include themify($themes){
            color: themed(theme-primary-color);
            background-color: themed(drop-down-hover);
          }
        }

        .el-select {
          .el-input__inner:hover, .el-input__inner:focus {
            @include themify($themes){
              border-color: themed(theme-primary-color);  
              background-color: themed(drop-down-hover);
            }
          }
          .el-input__inner {      
            width: 115px;
            padding-right: 10px;
            .is-active {
            @include themify($themes){
              color: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          }
          }   
        }  
        .el-select-dropdown__item:hover {
          @include themify($themes){
            background-color: themed(drop-down-hover);
          }
        }
        .el-select-dropdown {
          border-radius: 4px !important;
        }
        .betQueryDatepicker {
            .el-input {
              width: 250px !important;
            }
          }
        .operationBtn{
          border-radius: 5px;
          padding: 7px 15px;
          white-space: nowrap;
          cursor: pointer;

          &.dateSelectBtn{
            border: 1px solid #DCDFE6;
            &:hover,&.selected{
            color: #409EFF;
            border-color: #c6e2ff;
            background-color: #ecf5ff;
            }
          }
  
          &.findAndCollapse{
            color: #FFF;
              @include themify($themes){
                  background: themed(theme-primary-color);
                  border-color:themed(theme-primary-color);
                }
              &:hover{
                  @include themify($themes){
                      background: themed(theme-secondary-color);
                      border-color:themed(theme-secondary-color);
                    }
              }
          }
  
          &.cancel{
            color: #FFF;
              background-color: #F56C6C;
              border-color: #F56C6C;
              &:hover{
                  background: #f78989 !important;
                  border-color: #f78989 !important;
              }
          }
        }
        border: 1px solid #ddd;
        @include themify($themes){
          background-color: themed(theme-third-color);
        }
      }
    }
}