@import '../../../scss/web_base';

.NotificationWrapper {
  margin-left: auto;
  flex: 1;
  .bellNoti{
    font-size: 1rem;
    line-height: 0;
    cursor: pointer;
  }
  .bellNotiCircle{
    cursor: pointer;
    font-size: 1rem;
    line-height: 0;
    @include themify($themes){
      background-color: themed(notice-color);
    }
    padding: 7px;
    border-radius: 50%;
    display:block;
  }

  .blink {
    animation: blink-animation 1s steps(5, start) infinite;
  }

  @keyframes blink-animation {
    to {
      visibility: hidden;
    }
  }

  .reminderWrapper{
    position: relative;
  }
}

.ant-popover-inner{
  box-shadow: 0 0 10px rgba(0 ,0, 0 , 0.3);
}
.ant-popover-inner-content{
  padding: 0px;
}
.ant-popover-title{
  border-radius: 5px 5px 0 0;
  background-color: #f34646;
  padding: 0;
  height: 30px;
  line-height: 30px;
  color: #fff;
  font-size: 15px;
  text-align: center;
}
.reminderBox{
  .reminderContent{
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    background: #ffffff;
    width: 175px;
    min-height: 177px;
    max-height: 300px;
    position: relative;
    .noDataStyle{
      font-weight: 600 !important;
    }
    .contentScrollArea{
      flex: 1;
      overflow-y: auto;
      &::-webkit-scrollbar {
        width: 5px;
        border-radius: 5px;
      }
      &::-webkit-scrollbar-track {
        background: #f1f1f1;
      }
      &::-webkit-scrollbar-thumb {
        background: rgb(212, 212, 212);
        border-radius: 5px;
      }
      &::-webkit-scrollbar-thumb:hover {
        background: rgb(204, 204, 204);
      }
    .reminderRow{
      &:hover{
        cursor: pointer;
        @include themify($themes){
          background-color: themed(theme-third-color);
        }
      }
      font-weight: normal;
      padding: 12px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      color: black;
      border-bottom: 1px solid #ddd;
    }
  }

  }
}