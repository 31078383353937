@import '../../../scss/web_base';

.LotteryRebateWrapper{
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    min-width: 975px;
    width: 100%;
    @include themify($themes){
      font-family: themed(font-family);
      color: themed(data-table-text-color);
    }
    .hide {
      display: none;
    }
    .marginLeft5{
      margin-left: 2.2em;
    }
    .menuWrapper {
      background: white;
      height: 30px;
      width: 120px !important;
      text-align: left;
      border-radius: 3px;
      padding: 5px 10px 5px 10px;
    }
    .active {
      @include themify($themes){
        color: themed(theme-lighter-color);
        }
    }
    .subLotteryBtn {
      all: unset;
      color: #606266;
      border: 1px solid #DCDFE6;
      background-color: white;
      font-size:11.5px;
      height: 25px;
      border-radius: 0px;
      border-right:none;
      &.first{
        border-radius: 4px 0px 0px 4px;
      }
      &.last{
        border-radius: 0px 4px 4px 0px;
        border: 1px solid #DCDFE6;
        &:hover {
          border: 1px solid #DCDFE6;
        }
      }
      &.onlyOne{
        border: 1px solid #DCDFE6;
        border-radius: 4px;
      }
      &.active {
        background: #fa6b40;
        color:white;
        border: 1px solid #DCDFE6;
        border-right:none;
        &:hover {
          background: #fa6b40;
          color:white !important;
        }
      }
      &:hover {
        background: white;
        border: 1px solid #DCDFE6;
        border-right:none;
        @include themify($themes){
          color: themed(theme-primary-color);
        }
      }
      &:focus {
        outline: none !important;
        outline-width: 0;
        box-shadow: none !important;
      }
    }
    .el-tabs__item {
      font-size: 12px !important;
    }
    .el-input {
      font-size: 12px !important;
    }
    .lotteryMenu  {
      border: 1px solid rgb(220,220,220);
      border-radius: 3px;
      padding: 8px 20px;
      margin-right: 10px !important;
      @include themify($themes){
        border: 1px solid themed(table-border-color);
        background-color: themed(lottery-menu-color);
      }
      .el-radio{
        .el-radio__label { 
          font-size: 12px;
          @include themify($themes){
            color: themed(data-table-text-color);
          }
        } 
      }
      .el-radio__input {
        background:white;
        border-radius: 3px;
        padding:3px;
        height: 20px;
        width: 20px;
        @include themify($themes){
          border: 1px solid themed(table-border-color);
        }
        &.is-checked {
          background-color: #f96c41;
        }
        .el-radio__inner {
          border: none !important;
          background:white;
          margin: 1px;
          height: 10px;
          width: 10px;
        }
        .el-radio__inner::after{
          @include themify($themes){
            background:white;
            border: none;
          }
        }
        .is-checked {
          background-color: red;
          @include themify($themes){
            border:1px solid themed(theme-lighter-color);
            color: themed(theme-lighter-color);
          }
          .el-radio__label { 
            @include themify($themes){
              color: themed(theme-primary-color);
              }
          }
        }
      }
      }
      .button {
        color: $white;
        font-weight: 700;
        border-radius: 5px;
        outline: none;
        font-size: 12px;
        position: relative;
        padding: 5px 15px 5px 15px;
        box-shadow: none;
        &.find{
          background: #f56f62;
          border: 1px solid #f56f62;
        }
        &.generate{
          background-color: #E6A23C;
          border-color: #E6A23C;
        }
        &.handle{
          background-color: #409EFF;
          border-color: #409EFF;
        }
      }
      .bigWrapper {
        box-shadow: 0 1px 2px rgba(0,0,0,0.15);
        transition: box-shadow 0.3s ease-in-out;
        border-radius: 5px;
        border: 1px solid #ddd;
        width: 100%;
      }
      .filterWrapper {
        color: #5d6d7b;
        font-size: 12px;
        border-radius: 3px;
        border: 1px solid #dcdcdc;
          width: 100%;
          @include themify($themes){
            border: 1px solid themed(table-border-color);
            background-color: themed(theme-third-color);
          }
          input[type=text]  {
            height: 30px;
            width: 130px !important;
            padding: 5px 8px 5px 8px;
          }
          input[type=number] {
            font-size: 12px;
            width: 120px;
            height: 30px;
          }
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

        /* Firefox */
        input[type=number] {
          -moz-appearance: textfield;
        }
        .form-control {
          width: 17%;
        }
        .el-select-dropdown__item.selected,
        .el-select-dropdown__item.selected.hover {
          font-weight: 700;
          @include themify($themes){
            color: themed(theme-primary-color);
            background-color: themed(drop-down-hover);
          }
        }

        .el-select {
          .el-input__inner:hover, .el-input__inner:focus {
            @include themify($themes){
              border-color: themed(theme-primary-color);  
              background-color: themed(drop-down-hover);
            }
          }
          .el-input__inner {      
            width: 115px;
            padding-right: 10px;
            .is-active {
            @include themify($themes){
              color: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          }
          }   
        }  
        .el-select-dropdown__item:hover {
          @include themify($themes){
            background-color: themed(drop-down-hover);
          }
        }
        .el-select-dropdown {
          border-radius: 4px !important;
        }
        .betQueryDatepicker {
          width: 185px !important;
          .el-input__inner {
            width: 185px !important;
            padding: 5px 15px 5px 15px;
            padding-right: 0px;
          }
        }
      }
}