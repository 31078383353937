@import '../../../scss/_web_base';

.WhitelistManagementWrapper {
  position: relative;
  .thirdPartyGamesFooter{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .backIcon{
    cursor: pointer;
  }
  .tableWrapper{
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    padding: 10px;
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ddd;
    background: #ffffff;
  }

  .whiteListMenu{
    margin-bottom: 10px;
    width: 100%;
    display: flex;
    padding-left: 25px;
    .menu_item{
        cursor: pointer;
        margin: 0px 5px;
        padding: 3px 0px;
        border-bottom: 2px solid transparent;
        &.selected{
            font-weight: bold;
            @include themify($themes){
                border-bottom: 2px solid themed(theme-primary-color);
            }
        }
        &:hover{
          @include themify($themes){
              border-bottom: 2px solid themed(theme-primary-color);
              color: themed(theme-primary-color);
          }
      }
    }
}

  .el-textarea{
    width: 300px;
  }
  .messageTitleBox{
    width: 300px !important;
  }
  .el-input{
    width: 130px;
  }

  .el-input__inner{
    height: 30px !important;
  }

  .el-select-dropdown__item.selected,
  .el-select-dropdown__item.selected.hover {
    font-weight: 700;
    @include themify($themes){
      color: themed(theme-primary-color);
      background-color: themed(drop-down-hover);
    }
  }

  .el-select {
    .el-input__inner:hover, .el-input__inner:focus {
      @include themify($themes){
        border-color: themed(theme-primary-color);  
        background-color: themed(drop-down-hover);
      }
    }
    .el-input__inner {      
      width: 130px;
      .is-active {
      @include themify($themes){
        color: themed(theme-primary-color);
        border-color:themed(theme-primary-color);
      }
    }
    }   
  }  
  .el-select-dropdown__item:hover {
    @include themify($themes){
      background-color: themed(drop-down-hover);
    }
  }
  .el-select-dropdown {
    border-radius: 4px !important;
  }
  .betQueryDatepicker {
    .el-input {
      width: 220px !important;
    }
  }

  .thirdPartyGamesTable{
    .thirdPartyGamesTitle{
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include themify($themes){
        background: themed(theme-third-color);
      }
    }
    @include themify($themes) {
    border: 1px solid themed(table-border-color);
    }
    &>div:not(:last-child){
      @include themify($themes) {
      border-bottom: 1px solid themed(table-border-color);
      }
    }
    .thirdPartyGamesRow{
      display: flex;
      width: 100%;
      &>div{
        padding: 5px 10px;
      }
      &>div:first-child{
        @include themify($themes){
          background: themed(theme-third-color);
        }
        width: 25%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include themify($themes) {
        border-right: 1px solid themed(table-border-color);  
        }  
      }
    }
  }

  .addNewGameBox{
    display: flex;
    align-items: center;
    padding: 10px;
    @include themify($themes){
      background-color: themed(theme-third-color);
      border: 1px solid themed(table-border-color);  
    }
  }

  .operationBtn{
    border-radius: 5px;
    padding: 7px 10px;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    color: #FFF;
    @include themify($themes){
      background: themed(theme-primary-color);
      border-color:themed(theme-primary-color);
    }
    &:hover{
        @include themify($themes){
            background: themed(theme-secondary-color);
            border-color:themed(theme-secondary-color);
          }
    }

    &.stopAndStart{
      background-color: #E6A23C;
      border-color: #E6A23C;
      &:hover{
          background: #ebb563 !important;
          border-color: #ebb563 !important;
      }
  }

    &.editAndSave{
      @include themify($themes){
          background: themed(theme-primary-color);
          border-color:themed(theme-primary-color);
        }
      &:hover{
          @include themify($themes){
              background: themed(theme-secondary-color);
              border-color:themed(theme-secondary-color);
            }
      }
  }


    &.add, &.cancel{
      background-color: #F56C6C;
      border-color: #F56C6C;
      &:hover{
          background: #f78989 !important;
          border-color: #f78989 !important;
      }
      .plus_icon{
        margin-right: 3px;
        border-radius: 50%;
        padding: 0px 2px;
        display: inline;
        background-color: white !important;
      .el-icon-plus{
        color: #F56C6C;
      }
    }
    }
  }
}