@import "../../../scss/web_base";

.authFooter {
  padding: 0 15px;
  background-color: $white;
  box-shadow: 0 0 10px rgba($black, 0.1);
  z-index: 10000;
  height: 24px;
  position: relative;
  min-width: 1440px;
  @keyframes paoMaDeng {
    0% {
      transform: translateX(100%);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .paomadengWrapper {
    height: 100%;
    overflow: hidden;
    position: relative;
    .paomadengSpan {
      position: absolute;
      width: 100%;
      height: 100%;
      margin: 0;
      text-align: center;
      transform: translateX(5%);
    }
    .animate {
      animation: paoMaDeng 50s linear infinite;
    }
  }
  &:hover{
    .paomadengSpan {
      animation-play-state: paused;
    }
  }

  .footerMore {
    @include themify($themes){
      color: themed(footer-more-color);
    }
    position: absolute;
    right: 10px;
    background-color: $white;
    padding: 0px 1rem;
    top: 0px;
    .moreButton {
      cursor: pointer;
    }
  }
}
