@import '../../scss/web_base';

.oddsManageWrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  position: relative;
  .betQueryWrapper{
    padding: 0px !important;
  }
  table{
    height: 0px !important;
  }
}