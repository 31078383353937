@import '../../../scss/_web_base';

.winLostReportWrapper{
    font-size: 12px;
   .tabWrapper{
    display: flex;
    padding: 0px 20px;
    &>div{
      padding: 0px 10px;
      &:hover{
        cursor: pointer;
        color: #448eed;
      }
      &.selected::after,&:hover::after{
        content: '';
        display: block;
        width: 100%;
        height: 2px;
        background-color: #448eed;
        margin-top: 5px;
        box-sizing: content-box;
      }
    }
   }
}