@import '../../../scss/_web_base';

.classificationReportWrapper{
    font-size: 12px;
    font-size: 12px;
    color: #5d6d7b;
    position: relative;
    .noWrapStyle{
      white-space: nowrap;
    }

    .classificationReportTableWrapper{
      box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
      margin: 10px 0;
      border-radius: 5px;
      border: 1px solid #ddd;
      background: #ffffff;
      .headerTitle{
        border-top:1px solid #dcdcdc;
        border-left:1px solid #dcdcdc;
        border-right:1px solid #dcdcdc;
        @include themify($themes){
          background-color: themed(theme-third-color);
        } 
      }
      .menuWrapper {
        cursor: pointer;
        display: flex;
        flex-direction: row;
        border-bottom:1px solid #dcdcdc;
        .menuItem{
          border-bottom: 1px solid transparent;
        &.selected{
          @include themify($themes){
            border-bottom: 2px solid themed(theme-primary-color);
          }
        }
        &:hover{
          background-color: #ccdef5;
        }
      }
      }

      .combineTitleCell{
          height: 100%;
        &>div:last-child{
            border-top: 1px solid #ddd;
            &>div{
                flex: 1;
            }
            &>div:not(:last-child){
                border-right: 1px solid #ddd;
            }
        }
    }
    .combineBodyRowCell{
        height: 100%;
        display: flex;
        &>div{
            height: 100%;
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;
        }
        &>div:not(:last-child){
            border-right: 1px solid #ddd;
        }
    }
      th,td{
        height: 40.5px;
      }
    }
}