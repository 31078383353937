@import '../../scss/web_base';

.betQueryDetailWrapper{
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    min-width: 975px;
    width: 100%;
    @include themify($themes){
      font-family: themed(font-family);
    }
    .menuSelectedText {
      width: 90px !important;
    }
    .subselection {
      position:absolute;
      top:5;
      z-index:1;
    }
    .hide {
      display: none;
    }
    .menuWrapper {
      background: white;
      height: 30px;
      width: 120px !important;
      font-size: 12px;
      text-align: left;
      border-radius: 3px;
      padding: 5px 10px 5px 10px;
      color:black;
    }
    .bellNoti{
      font-size: 1rem;
      line-height: 0;
    }
    .bellNotiCircle{
      font-size: 1rem;
      line-height: 0;
      @include themify($themes){
        background-color: themed(notice-color);
      }
      padding: 7px;
      border-radius: 50%;
      display:block;
    }

    .filterWrapper {
      color: white;
      font-size: 12px;
      border-radius: 3px;
      border: 1px solid #dcdcdc;
        @include themify($themes){
            background-color: themed(theme-primary-color);
        }
        input {
          height: 30px;
          width: 95px !important;
          font-size: 12px;
          padding: 5px 8px 5px 8px;
        }
        .form-control {
          width: 17%;
        }
   
        .el-select-dropdown__item.selected,
        .el-select-dropdown__item.selected.hover {
          font-weight: 700;
          @include themify($themes){
            color: themed(theme-primary-color);
            background-color: themed(drop-down-hover);
          }
        }

        .el-select {
          .el-input__inner:hover, .el-input__inner:focus {
            @include themify($themes){
              border-color: themed(theme-primary-color);  
              background-color: themed(drop-down-hover);
            }
          }
          .el-input__inner {      
            width: 115px;
            padding-right: 10px;
            .is-active {
            @include themify($themes){
              color: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          }
          }   
        }  
        .el-select-dropdown__item:hover {
          @include themify($themes){
            background-color: themed(drop-down-hover);
          }
        }
        .el-select-dropdown {
          border-radius: 4px !important;
        }
        .betQueryDatepicker {
          width: 185px !important;
          .el-input__inner {
            width: 185px !important;
            padding: 5px 15px 5px 15px;
            padding-right: 0px;
          }
        }
        .searchButton {
          color: $white;
          font-weight: 700;
          @include themify($themes) {
            background: themed(search-button-color);
            border: 1px solid themed(search-button-color);
          }
          border-radius: 5px;
          outline: none;
          font-size: 12px;
          position: relative;
          padding: 5px 15px 5px 15px;
          box-shadow: none;
          &:hover {
            @include themify($themes) {
              background: themed(search-button-color);
              border: 1px solid themed(search-button-color);
            }
          }
        }
      }
}