@import '../../../scss//web_base';

.bankCardWrapper {
  position: relative;
  .bankCardtableWapper{
    box-shadow: 0 0 1px rgba(0, 0, 0, 10%);
    margin: 10px 0;
    border-radius: 5px;
    border: 1px solid #ddd;
    background: #ffffff;
    .noWrapStyle{
      white-space: nowrap;
      padding: 0px 20px;
    }
  }

  .operationBtn{
    border-radius: 5px;
    padding: 7px 15px;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    color: #FFF;
    background-color: #F56C6C;
    border-color: #F56C6C;
    &:hover{
        background: #f78989 !important;
        border-color: #f78989 !important;
    }
  }
}