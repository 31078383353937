@import '../../../scss/web_base';

.CurrentOddsSettingWarpper{
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    min-width: 975px;
    width: 100%;
    font-size: 12px;
    .radiobuttongroup{
      *{
        cursor: pointer;
      }
    }
    .oddHeader{
      border-collapse: collapse;
      font-size: 12px;
      padding: 7px;
      text-align: center;
      font-weight: 600;
      @include themify($themes){
        border-top: 1px solid themed(table-border-color);
        border-left: 1px solid themed(table-border-color);
        border-right: 1px solid themed(table-border-color);
        background-color: themed(theme-third-color);
        color: themed(table-header-text-color);
      }
      &.concatUpDown { 
        @include themify($themes){
          border-top: none;
          border-left: 1px solid themed(table-border-color);
          border-right: 1px solid themed(table-border-color);
          border-bottom: none;
        }
        &.drawbottom {
          @include themify($themes){
            border-bottom: 1px solid themed(table-border-color);
          }
        }
      }
      &.drawbottom {
        @include themify($themes){
          border-bottom: 1px solid themed(table-border-color);
        }
      }
    }
    .backgroundYellow {
      background-color: #FFFFCC;
      border:#ffc32e;
    }
    .iconFontSize{
      font-size: 15px;
    }
    .hideLabel {
      display: none !important;
    }
    .smallFixWidth{
      width: 38px;
    }
    @include themify($themes){
      font-family: themed(font-family);
    }
    .hide {
      display: none;
    }
    .menuWrapper {
      font-size: 13px;
      border-radius: 2px;
      padding: 5px 5px 10px 5px;
      @include themify($themes){
        border: 1px solid themed(table-border-color);
        font-family: themed(font-family);
        color: themed(table-header-text-color);
      }
      .mainItem {
        padding: 3px 18px 3px 18px;
        margin: 3px;
        cursor: pointer;
        @include themify($themes){
          color: themed(table-header-text-color);
          border-bottom: 1px solid themed(table-border-color);
        }
      }
      .boxItem { 
        display: inline-block;
        padding: 0;  
        position: relative;
      }
      .boxItem:after
      {
        content:'';
        width:25em;
        @include themify($themes){
          color: themed(table-header-text-color);
          border-right: 1px solid themed(table-border-color);
        }
        margin: 1em;
      }
      :after {
        left:50%;
      }
      :before {
        right:50%;
      }
      .boxItem:first-of-type:before,
      .boxItem:last-of-type:after {
        display:none;
      }
      .boxContainer {
        cursor: pointer;
        margin: 3px;
        white-space:normal; 
        text-align:center;
        @include themify($themes){
          color: themed(table-header-text-color);
        }
      }
      .mainActive {
        @include themify($themes){
          border-bottom: 2px solid themed(theme-primary-color);
          color: themed(theme-primary-color);
        }
      }
    }
      /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .form-control {
      width: 17%;
    }
    input[type='number'] {
      height: 20px !important;
      width: 50px !important;
      font-size: 12px;
      padding:0;
      text-align: center;
      border-radius: 3px;
      @include themify($themes){
        border: 1px solid themed(table-border-color);
        color: themed(table-header-text-color);
      }
    }
    /* ========== */
    .content {
      padding: 6px !important;
    }
    .concatUpDownContent {
      padding: 0px 5px 0px 5px !important;
    }
    .concatLeftRightContent {
      padding: 0px 5px 0px 5px !important;
    }
    
    .active {
      @include themify($themes){
        color: themed(theme-lighter-color);
        }
    }
    .form-control {
      width: 17%;
    }
    .subMenu  {
      width: 100%;
      @include themify($themes){
        background-color: themed(theme-third-color);
      }
    }

    .filler {
      height: 28px;
    }
    .pk10Square{
      font-weight: bold;
      font-size: 12px;
      width: 22px;
      height: 22px;
      color: white;
      border-radius: 5px;
      border:3px;
      justify-content: center;
      align-items: center;
      padding:2px;
  
      &.square_1{
        @include themify($themes){
          background-color: themed(table-square-1);
        }
      }
      &.square_2{
        @include themify($themes){
          background-color: themed(table-square-2);
        }
      }
      &.square_3{
        @include themify($themes){
          background-color: themed(table-square-3);
        }
      }
      &.square_4{
        @include themify($themes){
          background-color: themed(table-square-4);
        }
      }
      &.square_5{
        @include themify($themes){
          background-color: themed(table-square-5);
        }
      }
      &.square_6{
        @include themify($themes){
          background-color: themed(table-square-6);
        }
      }
      &.square_7{
        @include themify($themes){
          background-color: themed(table-square-7);
        }
      }
      &.square_8{
        @include themify($themes){
          background-color: themed(table-square-8);
        }
      }
      &.square_9{
        @include themify($themes){
          background-color: themed(table-square-9);
        }
      }
      &.square_10{
        @include themify($themes){
          background-color: themed(table-square-10);
        }
      }
  
    }
    .hk6Ball{
      width: 25px;
      height: 25px;
      line-height: 19px;
      text-align: center;
      box-sizing: border-box;
      color: #626262;
      font-size: 10px;
      font-weight: bold;
      border-radius: 50%;
      border: 3px solid;
      padding: 0;
      background-color: #EAF3FF;
  
      &.ball_red{
          border-color: $ballRed !important;
      }
      &.ball_blue{
          border-color: $ballBlue !important;
      }
      &.ball_green{
          border-color: $ballGreen !important;
      }
      &.ball_lightBlue{
        border-color: #5ea1f7 !important;
      }
      &.ball_yellow{
        border-color: #ffc32e !important;
    }
    }
    .blueBall{
      width: 25px;
      height: 25px;
      line-height: 15px;
      text-align: center;
      box-sizing: border-box;
      font-size: 10px;
      font-weight: bold;
      border-radius: 50%;
      border: 5px solid;
      padding: 0; 
      &.light{
          color: #09485d;
          border-color: #96d2e4 !important;
          background-color: #96d2e4;
      }
      &.deep{
        color: #eee;
        border-color: #214b8a !important;
        background-color: #214b8a;
      }
    }
    .hk6_sebo{
      width: 100%;
      height: 100%;
      color: white;
      display: flex;
      padding: 7%;
      justify-content: center;
      align-items: center;
      &.redbo{
        background: $ballRed;
      }
      &.bluebo{
        background-color: $ballBlue;
      }
      &.greenbo{
        background-color: $ballGreen;
      }
    }
    .button {
      color: $white;
      font-weight: 700;
      border-radius: 5px;
      outline: none;
      font-size: 12px;
      position: relative;
      padding: 5px 15px 5px 15px;
      box-shadow: none;
      &.save{
        background: #f56f62;
        border: 1px solid #f56f62;
      }
      &.cancel{
        background-color: #409EFF;
        border-color: #409EFF;
      }
    }  
}