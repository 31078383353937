@import '../../scss/web_base';

.bogusBetWarningWrapper{
    font-size: 12px;
    padding: 12px 12px 60px 12px;
    font-size: 12px;
    color: #5d6d7b;
    position: relative;

    .betWarningInput{
        width: 50%;
        max-width: 120px;
        .el-input__inner{
            height: 30px !important;
        }
    }

    .el-select-dropdown__item.selected,
    .el-select-dropdown__item.selected.hover {
      font-weight: 700;
      @include themify($themes){
        color: themed(theme-primary-color);
        background-color: themed(drop-down-hover);
      }
    }

    .el-select {
      .el-input__inner:hover, .el-input__inner:focus {
        @include themify($themes){
          border-color: themed(theme-primary-color);  
          background-color: themed(drop-down-hover);
        }
      }
      .el-input__inner {      
        width: 120px;
        height: 30px !important;
        padding-right: 10px;
        .is-active {
        @include themify($themes){
          color: themed(theme-primary-color);
          border-color:themed(theme-primary-color);
        }
      }
      }   
    }  
    .el-select-dropdown__item:hover {
      @include themify($themes){
        background-color: themed(drop-down-hover);
      }
    }
    .el-select-dropdown {
      border-radius: 4px !important;
    }

    .menuRow{
        padding: 5px 10px;
        width: 100%;
        display: flex;
        @include themify($themes){
            background-color: themed(theme-third-color);
          }
        .currentSelected{
            border: 1px solid #d2d7e0;
            border-radius: 3px;
            cursor: pointer;
            position: relative;
            width: 120px;
            height: 30px;
            text-align: center;
            line-height: 30px;
            padding: 0;
            background-color: white;
            @include themify($themes){
                color: themed(theme-primary-color);
              }
            &:hover{
                @include themify($themes){
                    background-color: themed(theme-primary-color) !important;
                  }
                color: white !important;
                .arrow_down{
                    border-top: 5px solid white !important;
                }
            }
            .arrow_down{
                width: 0;
                height: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                display: inline-block;
                margin: 2px 10px;
                @include themify($themes){
                    border-top: 5px solid themed(theme-primary-color) !important;
                  }
            }
            .bogusBetWarningMenuWrapper{
                position: absolute;
                z-index: 2;
            }
        }
    }
    .bogusBetWarningTableWrapper{
        box-shadow: 0 0 10px rgba(0,0,0,0.1);
        padding: 15px 10px;
        margin: 10px 0;
        border-radius: 5px;
        border: 1px solid #ddd;
        background: #ffffff;
        display: flex;
        justify-content: center;
        align-items: center;
    .bogusBetWarningTable{
        width: 100%;
        border: 1px solid #dcdcdc;
        &>div:not(:last-child){
            border-bottom: 1px solid #dcdcdc;
        }
    .bogusBetWarningTableHeader{
        @include themify($themes){
            background-color: themed(theme-third-color);
          }
        display: flex;
        &>div{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 37px;
        }
        &>div:first-child{
            @include themify($themes){
                background-color: themed(theme-third-color);
              }
            width: 5% !important;
        }
        &>div:not(:last-child){
            border-right: 1px solid #dcdcdc;
        }
        &>div:not(:first-child),&>div:not(:last-child){
            width: 16.66%;
        }
    }
    .bogusBetWarningTableSettingAddRow{
        display: flex;
        &>div:not(:first-child),&>div:not(:last-child){
            width: 16.66%;
        }
        &>div:first-child{
            @include themify($themes){
                background-color: themed(theme-third-color);
              }
            width: 5% !important;
        }
        &>div{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 37px;
        }
        &>div:not(:last-child){
            border-right: 1px solid #dcdcdc;
        }
        .betWarningInput{
            width: 50%;
            max-width: 120px;
            .el-input__inner{
                height: 30px !important;
            }
        }
    }


    .bogusBetWarningTableSettingRow{
        display: flex;
        &>div:not(:first-child),&>div:not(:last-child){
            width: 16.66%;
        }
        &>div:first-child{
            @include themify($themes){
                background-color: themed(theme-third-color);
              }
            width: 5% !important;
        }
        &>div{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 37px;
        }
        &>div:not(:last-child){
            border-right: 1px solid #dcdcdc;
        }
    }
    .operationBtn{
        border-radius: 5px;
        padding: 7px 15px;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        color: #FFF;

        &.editAndSave{
            @include themify($themes){
                background: themed(theme-primary-color);
                border-color:themed(theme-primary-color);
              }
            &:hover{
                @include themify($themes){
                    background: themed(theme-secondary-color);
                    border-color:themed(theme-secondary-color);
                  }
            }
        }

        &.add{
            background-color: #67C23A;
            border-color: #67C23A;
            &:hover{
                background: #85ce61 !important;
                border-color: #85ce61 !important;
            }
        }
        &.remove{
            background-color: #F56C6C;
            border-color: #F56C6C;
            &:hover{
                background: #f78989 !important;
                border-color: #f78989 !important;
            }
        }
      }
    }
    }
}