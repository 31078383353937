@import '../../../scss/_web_base';

.transferFlowsDetailWrapper{
    height: max-content;
    display: flex;
    background-color: $white;
    border-radius: 5px;
    flex-direction: column;
    min-width: 975px;
    width: 100%;
    @include themify($themes){
      font-family: themed(font-family);
    }

    .filterWrapper {
        color:white;
        font-size: 12px;
        border-radius: 3px;
        border: 1px solid #dcdcdc;
        @include themify($themes){
            background-color: themed(theme-primary-color);
        }

        .el-input{
            width: 120px;
            margin-left: 5px;
          }
  
          .el-input__inner{
            height: 30px !important;
          }
   
        .el-select-dropdown__item.selected,
        .el-select-dropdown__item.selected.hover {
          font-weight: 700;
          @include themify($themes){
            color: themed(theme-primary-color);
            background-color: themed(drop-down-hover);
          }
        }

        .el-select {
          .el-input__inner:hover, .el-input__inner:focus {
            @include themify($themes){
              border-color: themed(theme-primary-color);  
              background-color: themed(drop-down-hover);
            }
          }
          .el-input__inner {      
            width: 115px;
            padding-right: 10px;
            .is-active {
            @include themify($themes){
              color: themed(theme-primary-color);
              border-color:themed(theme-primary-color);
            }
          }
          }   
        }  
        .el-select-dropdown__item:hover {
          @include themify($themes){
            background-color: themed(drop-down-hover);
          }
        }
        .el-select-dropdown {
          border-radius: 4px !important;
        }
        .betQueryDatepicker {
            .el-input {
              width: 250px !important;
            }
          }
        .searchButton {
          color: $white;
          font-weight: 700;
          @include themify($themes) {
            background: themed(search-button-color);
            border: 1px solid themed(search-button-color);
          }
          border-radius: 5px;
          outline: none;
          font-size: 12px;
          position: relative;
          padding: 5px 15px 5px 15px;
          box-shadow: none;
          &:hover {
            @include themify($themes) {
              background: themed(search-button-color);
              border: 1px solid themed(search-button-color);
            }
          }
        }
      }
}