@import '../../../scss//web_base';

.userManagementMenuWrapper {
  padding: 12px 12px 60px 12px;
  position: relative;
  width: 100%;
  .backIcon{
        cursor: pointer;
    }
  .userManagementMenu{
      width: 100%;
      display: flex;
      padding-left: 25px;
      .menu_item{
          cursor: pointer;
          margin: 0px 5px;
          padding: 3px 0px;
          border-bottom: 2px solid transparent;
          &.selected{
              font-weight: bold;
              @include themify($themes){
                  border-bottom: 2px solid themed(theme-primary-color);
              }
          }
          &:hover{
            @include themify($themes){
                border-bottom: 2px solid themed(theme-primary-color);
                color: themed(theme-primary-color);
            }
        }
      }
  }
}