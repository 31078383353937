@import '../../../scss/web_base';

.pagination{
  padding-top: 1%;
  .form-control{
    display: inline;
  }
  .box{
    cursor: pointer;
    font-weight: 700;
    color: rgb(95, 95, 95);
    padding: 5px;
    min-width: 2rem;
    min-height: 1.7rem;
    text-align: center;
    border-radius: 2px;
    font-size: 12px;
    background: rgb(248, 248, 248);
    @include themify($themes){
      color: themed(pagination-text-color);
    }
  }

  .paginationActive{     
    color: $white !important;
    @include themify($themes){
      border:1px solid themed(theme-primary-color);
      background-color: themed(theme-primary-color);
    }
  }

  .inactive {
    color:rgb(187, 187, 187);
  }

  input{
    width: 2.3rem;
    height: 1.6rem;
    font-size: 12px;
    text-align: center;
  }
    /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
}
.selectionWrapper {
  .el-select-dropdown__item.selected,
  .el-select-dropdown__item.selected.hover {
    font-weight: 700;
    @include themify($themes){
      background-color: themed(drop-down-hover);
      color: themed(theme-primary-color);
    }
  }
  .el-select {
    max-height: 5px;
    .el-input__inner:hover, .el-input__inner:focus {
      @include themify($themes){
        border-color: themed(theme-primary-color);
      }
    }
    .el-input__inner {
      padding:5px 28px 5px 5px;
      font-size:12px;
      height: 1.7rem;
      width: 95px !important;
      text-align: right;
      .is-active {
      @include themify($themes){
        color: themed(theme-primary-color);
        border-color:themed(theme-primary-color);
      }
    }
    }
  }
  .el-select-dropdown__item:hover {
    @include themify($themes){
      background-color: themed(drop-down-hover);
    }
  }
  .el-select-dropdown {
    border-radius: 4px !important;
  }
}