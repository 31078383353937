@import '../../../scss/web_base';

.latestAnnouncementModalWrapperWeb {
  border-radius: 5px;
  width: 850px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 34px;
    color: $white;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .modalContent {
    height: auto;
    max-height: 550px !important;
    overflow-y: scroll;
    &::-webkit-scrollbar {
      width: 4px;
    }
    &::-webkit-scrollbar-thumb {
        background: #ADACAC;
      border-radius: 5px;
    }
    .titleNotice{
      width: 10%;

    }
    .contentNotice{
      width: 75%;
    }
    .dateNotice{
      width: 15%;
    }
    .operationBtn{
      border-radius: 5px;
      padding: 7px 10px;
      line-height: 1;
      white-space: nowrap;
      cursor: pointer;
      color: #FFF;
      @include themify($themes){
        background: themed(theme-primary-color);
        border-color:themed(theme-primary-color);
      }
      &:hover{
          @include themify($themes){
              background: themed(theme-secondary-color);
              border-color:themed(theme-secondary-color);
            }
      }
  
      &.editAndSave{
        @include themify($themes){
            background: themed(theme-primary-color);
            border-color:themed(theme-primary-color);
          }
        &:hover{
            @include themify($themes){
                background: themed(theme-secondary-color);
                border-color:themed(theme-secondary-color);
              }
        }
    }
    &.cancel, &.editAndSave{
      padding: 10px 15px !important;
    }
  
      &.add, &.cancel,&.serchAndDetail{
        background-color: #F56C6C;
        border-color: #F56C6C;
        &:hover{
            background: #f78989 !important;
            border-color: #f78989 !important;
        }
        .plus_icon{
          margin-right: 3px;
          border-radius: 50%;
          padding: 0px 2px;
          display: inline;
          background-color: white !important;
        .el-icon-plus{
          color: #F56C6C;
        }
      }
      }
    }
    width: 100% !important;
    background-color: white;
    font-size: 12px;
    height: max-content;
    margin: 0;
    flex-direction: column;
    color: #5d6d7b;
    @include themify($themes) {
      font-family: themed(font-family);
    }
    .confirmText{
      text-align: center;
    }
    .exclamationIcon {
      height: 7em;
      width: 7em;
      circle{
        @include themify($themes){
          fill: themed(theme-secondary-color) !important;
        }
      }
    }
    .successText {
      @include themify($themes) {
        color: themed(success-modal-text-color)
      }
    }
  }
  .modalFooter {
    font-size: 16px;
    height: max-content;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    @include themify($themes) {
      background-color:themed(theme-third-color);
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }
}

