@import '../../scss/_web_base';

.onlineStatisticsWrapper {
    @include themify($themes){
      font-family: themed(font-family);
    }
    color:#5a6167 !important;
    .inputStyle{
        width: 120px;
        margin: 0px 10px;
    }
    .searchBox{
        display: flex;
        align-items: center;
        padding: 10px;
        @include themify($themes){
            background-color: themed(theme-third-color);
          }
    }

    .operationBtn{
        border-radius: 5px;
        padding: 7px 15px;
        line-height: 1;
        white-space: nowrap;
        cursor: pointer;
        color: #FFF;
        background-color: #F56C6C;
        border-color: #F56C6C;
        &:hover{
            background: #f78989 !important;
            border-color: #f78989 !important;
        }
      }

}