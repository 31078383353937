@import '../../../scss/web_base';

.copyOddsDiffModalWrapper {
  border-radius: 5px;
  width: 850px;
  @include themify($themes) {
    font-family: themed(font-family);
  }
  .modalHeader {
    line-height: 34px;
    color: $white;
    font-size: 14px;
    font-weight: bold;
    margin: 0;
    @include themify($themes) {
      background-color: themed(theme-primary-color);
      font-family: themed(font-family);
    }
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    .closeIcon {
      cursor: pointer;
    }
  }
  .drawhalfbox {
    @include themify($themes){
        border-left: 1px solid themed(table-border-color);
        border-right: 1px solid themed(table-border-color);
        border-bottom: 1px solid themed(table-border-color);
      }
  }
  .modalContent {
    width: 100% !important;
    background-color: white;
    font-size: 12px;
    margin: 0;
    max-height: 500px;
    padding-bottom: 2em;
    color: #5d6d7b;
    border:none;
    @include themify($themes) {
      font-family: themed(font-family);
    }
    .confirmText{
      text-align: center;
    }
    .form-check-input[type=checkbox] {
      border-radius: 0.15em;    }
    .exclamationIcon {
      height: 7em;
      width: 7em;
      circle{
        @include themify($themes){
          fill: themed(theme-secondary-color) !important;
        }
      }
    }
    .successText {
      @include themify($themes) {
        color: themed(success-modal-text-color)
      }
    }
  }
  .el-input__inner {
    font-size: 12px;
    height: 30px;
  }
  .loadingwrapper {
    background-color:#000;
    opacity: 0.3;
    position: absolute;
    padding-top: 30%;
    height: 100%;
    width: 100%;
    z-index: 5;
  }
  .tableWrapper {
    max-height: 400px;
    overflow: auto;
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(212, 212, 212);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(204, 204, 204);
    }
  }
  .modalFooter {
    font-size: 16px;
    margin: 0;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    @include themify($themes) {
      background-color:themed(theme-third-color);
    }
    .confirmButton {
      width: 120px;
      height: 36px;
      color: $white;
      border-radius: 4px;
      font-size: 16px;
      text-align: center;
      @include themify($themes){
        background-color: themed(theme-primary-color);
        border: 1px solid themed(theme-primary-color);
      }
    }
  }

  
      /* Dropdown Button */
.dropbtn {
  background-color: #ffffff;
  font-size: 13px;
  border:1px solid #bfcbd9;
  cursor: pointer;
  border-radius: 3px;
  min-width: 180px;
  padding: 5px 10px 5px 10px;
  text-align: left;
  .wlimit {
    min-width: 152px
  }
}

/* Dropdown button on hover & focus */
.dropbtn:hover, .dropbtn:focus {
  background-color: #f8f8f8;
  border:1px solid #bfcbd9;
}

/* The search field */
#myInput {
  box-sizing: border-box;
  background-position: 14px 12px;
  background-repeat: no-repeat;
  font-size: 13px;
  border: none;
  min-width: 185px;
  border-bottom: 1px solid #ddd;
}

/* The search field when it gets focus/clicked on */
#myInput:focus {outline: 3px solid #ddd;}

/* The container <div> - needed to position the dropdown content */
.dropdown {
  position: relative;
  display: inline-block;
}

/* Dropdown Content (Hidden by Default) */
.dropdown-content {
  font-size: 13px;
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 3px;
  z-index: 1;
  cursor: pointer;
  .tooverflow {
    max-height: 450px;
    overflow: auto;
    padding: 0 10px 0 10px;
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
    &::-webkit-scrollbar-thumb {
      background: rgb(212, 212, 212);
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: rgb(204, 204, 204);
    }
  }
  p {
    padding: 5px;
    margin: 0;
  }
  p:hover, .hightlight {
    background-color: rgb(240, 240, 240);
  }

}

/* Change color of dropdown links on hover */
.dropdown-content a:hover {background-color: #f1f1f1}

/* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
.show {display:block;}
}

