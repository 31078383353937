@import '../../../scss/_web_base';

.BankCardSettingWrapper {
  width: 100%;
  position: relative;
  .thirdPartyGamesFooter{
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .backIcon{
    cursor: pointer;
  }

  .thirdPartyGamesTable{
    .thirdPartyGamesTitle{
      padding: 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      @include themify($themes){
        background: themed(theme-third-color);
      }
    }
    @include themify($themes) {
    border: 1px solid themed(table-border-color);
    }
    &>div:not(:last-child){
      @include themify($themes) {
      border-bottom: 1px solid themed(table-border-color);
      }
    }
    .thirdPartyGamesRow{
      display: flex;
      width: 100%;
      &>div{
        padding: 5px 10px;
      }
      &>div:first-child{
        @include themify($themes){
          background: themed(theme-third-color);
        }
        width: 25%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        @include themify($themes) {
        border-right: 1px solid themed(table-border-color);  
        }  
      }

      .el-input__inner{
        height: 30px !important;
    }

      .el-select-dropdown__item.selected,
    .el-select-dropdown__item.selected.hover {
      font-weight: 700;
      @include themify($themes){
        color: themed(theme-primary-color);
        background-color: themed(drop-down-hover);
      }
    }

    .el-select {
      .el-input__inner:hover, .el-input__inner:focus {
        @include themify($themes){
          border-color: themed(theme-primary-color);  
          background-color: themed(drop-down-hover);
        }
      }
      .el-input__inner {      
        height: 30px !important;
        padding-right: 10px;
        .is-active {
        @include themify($themes){
          color: themed(theme-primary-color);
          border-color:themed(theme-primary-color);
        }
      }
      }   
    }  
    .el-select-dropdown__item:hover {
      @include themify($themes){
        background-color: themed(drop-down-hover);
      }
    }
    .el-select-dropdown {
      border-radius: 4px !important;
    }
    }

  }

  .addNewGameBox{
    display: flex;
    align-items: center;
    padding: 10px;
    @include themify($themes){
      background-color: themed(theme-third-color);
    }
  }

  .operationBtn{
    border-radius: 5px;
    padding: 7px 10px !important;
    line-height: 1;
    white-space: nowrap;
    cursor: pointer;
    color: #FFF;
    @include themify($themes){
      background: themed(theme-primary-color);
      border-color:themed(theme-primary-color);
    }
    &:hover{
        @include themify($themes){
            background: themed(theme-secondary-color);
            border-color:themed(theme-secondary-color);
          }
    }

  //   &.editAndSave{
  //     @include themify($themes){
  //         background: themed(theme-primary-color);
  //         border-color:themed(theme-primary-color);
  //       }
  //     &:hover{
  //         @include themify($themes){
  //             background: themed(theme-secondary-color);
  //             border-color:themed(theme-secondary-color);
  //           }
  //     }
  // }
  // &.cancel, &.editAndSave{
  //   padding: 10px 15px !important;
  // }

    &.add, &.cancel{
      background-color: #F56C6C;
      border-color: #F56C6C;
      &:hover{
          background: #f78989 !important;
          border-color: #f78989 !important;
      }
      .plus_icon{
        margin-right: 3px;
        border-radius: 50%;
        padding: 0px 2px;
        display: inline;
        background-color: white !important;
      .el-icon-plus{
        color: #F56C6C;
      }
    }
    }
  }
  tr{
    height: 45px !important;
  }
}