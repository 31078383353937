@import '../../../scss/web_base';

.oddsSettingHeader {
    width: 100%;
    border-radius: 5px 5px 0 0;
    margin-bottom: 10px;
    @include themify($themes){
        background-color: themed(theme-primary-color);
    }
    padding: 10px 18px 10px 18px;
    font-size: 12px;
    display: flex;
    align-items: center;

    .NotificationWrapper {
      flex: none !important;
    }
    .refreshIcon{
      width: 30px;
    }

    .todayProfit, .timeToClose{
      color: white;
      margin-left: 15px;
    }
    .preInstallment{
      margin-left: 15px;
      margin-right: 15px;
    }

    .currentInstallment, .preInstallment{
      color: white;
      span{
        @include themify($themes){
          color: themed(installment-text);
        }
      }
    }

    .refreshButton {
        text-align: center;
        border-radius: 5px;
        height: 30px;
        width: 55px;
        line-height: 30px;
        color: white;
        cursor: pointer;
        @include themify($themes){
            background-color: themed(search-button-color);
        }
        &.select {
          background-color: #67C23A;
        }
        &.deselect {
          background-color: #E6A23C;
        }
    }
    .countDownTimer{
      color: white;
    }
    .bellNoti{
        cursor: pointer;
        font-size: 1rem;
        line-height: 0;
        color: white;
      }
    .bellNotiCircle{
        cursor: pointer;
        color: white;
        font-size: 1rem;
        line-height: 0;
        @include themify($themes){
            background-color: themed(notice-color);
        }
        padding: 7px;
        border-radius: 50%;
        display:block;
    }
    .el-select-dropdown__item.selected,
    .el-select-dropdown__item.selected.hover {
      font-weight: 700;
      @include themify($themes){
        color: themed(theme-primary-color);
        background-color: themed(drop-down-hover);
      }
    }

    .el-select {
      .el-input__inner:hover, .el-input__inner:focus {
        @include themify($themes){
          border-color: themed(theme-primary-color);  
          background-color: themed(drop-down-hover);
        }
      }
      .el-input__inner {      
        width: 115px;
        height: 30px !important;
        padding-right: 10px;
        .is-active {
        @include themify($themes){
          color: themed(theme-primary-color);
          border-color:themed(theme-primary-color);
        }
      }
      }   
    }  
    .el-select-dropdown__item:hover {
      @include themify($themes){
        background-color: themed(drop-down-hover);
      }
    }
    .el-select-dropdown {
      border-radius: 4px !important;
    }
    .pk10Square{
      font-weight: bold;
      font-size: 12px;
      width: 22px;
      height: 22px;
      color: white;
      border-radius: 5px;
      border:3px;
      display: flex;
      justify-content: center;
      align-items: center;
  
      &.square_1{
        @include themify($themes){
          background-color: themed(table-square-1);
        }
      }
      &.square_2{
        @include themify($themes){
          background-color: themed(table-square-2);
        }
      }
      &.square_3{
        @include themify($themes){
          background-color: themed(table-square-3);
        }
      }
      &.square_4{
        @include themify($themes){
          background-color: themed(table-square-4);
        }
      }
      &.square_5{
        @include themify($themes){
          background-color: themed(table-square-5);
        }
      }
      &.square_6{
        @include themify($themes){
          background-color: themed(table-square-6);
        }
      }
      &.square_7{
        @include themify($themes){
          background-color: themed(table-square-7);
        }
      }
      &.square_8{
        @include themify($themes){
          background-color: themed(table-square-8);
        }
      }
      &.square_9{
        @include themify($themes){
          background-color: themed(table-square-9);
        }
      }
      &.square_10{
        @include themify($themes){
          background-color: themed(table-square-10);
        }
      }
  
    }
    .hk6Ball{
      width: 25px;
      height: 25px;
      line-height: 19px;
      text-align: center;
      box-sizing: border-box;
      color: #626262;
      font-size: 10px;
      font-weight: bold;
      border-radius: 50%;
      border: 3px solid;
      padding: 0;
      background-color: #EAF3FF;
  
      &.ball_red{
          border-color: $ballRed !important;
      }
      &.ball_blue{
          border-color: $ballBlue !important;
      }
      &.ball_green{
          border-color: $ballGreen !important;
      }
    }
}