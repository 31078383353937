@import '../../scss/web_base';

.agentDataTableWrapper {
  width: 100%;
  @include themify($themes) {
    border-right: 1px solid themed(table-border-color);
    border-bottom: 1px solid themed(table-border-color);
  }
  .textRight {
    text-align: right !important;
  }
  .textLeft {
    text-align: left !important;
  }
  .agentDataTable{
    width: 100%;
    background-color: $white;
    text-align: center;
    border-collapse: collapse;
    overflow: hidden;
    @include themify($themes) {
      font-family: themed(font-family);
    }
    .deletedData {
      text-decoration: line-through !important;
      label{
        text-decoration: line-through !important;
      }
    }
    .smallCol {
      width: 60px;
    }
    .mediumSmallCol {
      width: 210px;
    }
    .backgroundColored{
      @include themify($themes){
        background-color: themed(theme-third-color);
      }
      img{
        width: 30px;
      }
    }
    .backgroundGrey{
     background-color: #f9f9f9;
    }
    .radioButtonItem {
      svg {
        margin-top: 5px;
        font-size: 1.3em;
        @include themify($themes) {
          fill: themed(theme-primary-color);
          stroke: themed(theme-primary-color);
        } 
      }
    }
    .buttonURL {
      background: none!important;
      border: none;
      padding: 0!important;
      font-size: 12px;
      /*input has OS specific font-family*/
      @include themify($themes) {
        color: themed(settled-unsettled-installment);
      }
      cursor: pointer;
    }
    .buttonControl {
      color: $white;
      font-weight: 700;
      border-radius: 5px;
      outline: none;
      font-size: 12px;
      position: relative;
      padding: 5px 15px 5px 15px;
      box-shadow: none;

      &.find{
        background: #f56f62;
        border: 1px solid #f56f62;
      }
      &.edit{
        background: #67C23A;
        border: 1px solid #67C23A;
      }
      &.generate{
        background-color: #E6A23C;
        border-color: #E6A23C;
      }
      &.handle{
        background-color: #409EFF;
        border-color: #409EFF;
      }
    }
    .controlButton {
      margin-top: 7px;
      margin-bottom: 7px;
      color: $white;
      border: none;
      @include themify($themes){
        background-color: themed(theme-primary-color);
      }
      border-radius: 3px;
      outline: none;
      font-size: 11.5px;
      position: relative;
      padding: 3px 9px;
      box-shadow: none;
      &:hover {
        @include themify($themes){
          background-color: themed(theme-secondary-color);
        }
      }
    }
    input[type='number'] {
      margin: 0 auto;
      height: 26px;
      max-width: 120px !important;
      font-size: 12px;
      text-align: center;
      border-radius: 3px;
      @include themify($themes){
        border: 1px solid themed(table-border-color);
        color: themed(table-header-text-color);
      }
      &.smallInput {
        width:65px !important;
      }
      &.isChange {
        background-color: rgb(255, 255, 215);
      }
      &.isConsolidateChange {
        background-color: rgb(255, 217, 217);
      }
    }
      /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
    td,th{
      color: #5d6d7b;
      font-size: 12px;
      font-weight: normal;
      padding: 6px 4px;
      @include themify($themes) {
        border: 1px solid themed(table-border-color);
      }
      border-top: none;
      border-left: none;
      &.nopadding {
        padding: 0px !important;
        @include themify($themes) {
          border-top: 1px solid themed(table-border-color);
        }
      }
      &.leftNoBorder {
        border-left: none;
      }
    }
    th{
      font-weight: normal !important;
    }
    .tableHeader{
      @include themify($themes){
        background-color: themed(theme-third-color);
        color: themed(table-header-text-color);
        border-top: 1px solid themed(table-border-color);
      }
      font-size: 12px;
      font-weight: normal;
    }
    thead {
      tr {
        th {
          &:last-child {
            border-right: none;
          }
        }
      }
    }
    tbody {
      .emptyIcon {
        height: 8em;
        width: 8em;
        margin-top: 30px;
      }
      .emptyTextWrapper {
        height: 5em;
        font-size: 16px;
        @include themify($themes) {
          color: themed(table-header-text-color)
        }
      }
      .haveControl {
        border-bottom: none !important;
      }
      tr {
        .rowSpan {
          border-bottom: none;
          border-right:none;
        }
        td {
          &:last-child {
            border-right: none;
          }
        }
        &:last-child {
          td {
            border-bottom: none;
          }
          &.leftNoBorder {
                @include themify($themes) {
                  border-bottom: 1px solid themed(table-border-color);
                }
          }
        }
        .topLeftBorderNone {
          border-top: none !important;
          border-left: none !important;
        }
      }
    }
    .tableBodyRow{

      &.success{
        background-color: #e5f7df !important;
      }
      &.fail{
        background-color: #f7e1de !important;
      }
      color: #5A6167;
      .betOrderNumber{
        text-decoration: underline;
        @include themify($themes){
          color: themed(settled-unsettled-row-no);
        }
      }
      .lotteryName{
          display: block;
      }
      .installment{
        @include themify($themes){
          color: themed(settled-unsettled-installment);
        }
      }
      .atTag{
        white-space: nowrap;
        width: 45px;
        text-align: left;
        .green {
          @include themify($themes){
            color: themed(settled-unsettled-installment);
          }
        }
      }
      .negativeValue{
        @include themify($themes){
          color: themed(records-sum-negative-text-color);
        }
      }
      .positiveValue{
        @include themify($themes){
          color: themed(records-sum-positive-text-color);
        }
      }
      .offline{
        color: #ccc;
      }
      .content{
        @include themify($themes){
          color: themed(settled-unsettled-bet-content);
        }
      }
      .odds{
        @include themify($themes){
          color: themed(settled-unsettled-bet-odds);
        }
      }
      .url {
        cursor:pointer;
        text-decoration: underline;
        color: #448eed;
      }
      .urlNoUnderline {
        cursor:pointer;
      }
      &:hover{
        @include themify($themes){
          background-color: themed(table-hover);
        }
      }
    }
    .tableSum {
      font-weight: 600;
      &.noPadding{
      padding: 0 !important;
      }
      @include themify($themes) {
        background: themed(theme-third-color);
      }
      .positiveValue {
        @include themify($themes) {
          color: themed(third-party-records-sum-positive-text-color);
        }
      }
      .negativeValue {
        @include themify($themes) {
          color: themed(third-party-records-sum-negative-text-color);
        }
      }
    }
  }
}