@import '../../../scss//web_base';

.SystemLogsWrapper {
  position: relative;
  .el-tabs__header {
    margin: 0 0px 10px 0px !important;
  }
  .el-tabs__item {
    font-size: 12px;
    height: 20px;
    line-height: 5px;
  }
  .filterWrapper {
    color: #5d6d7b;
    font-size: 12px;
    border-radius: 3px;
    border: 1px solid #dcdcdc;
    width: 100%;
    @include themify($themes){
      border: 1px solid themed(table-border-color);
      background-color: themed(theme-third-color);
    }
    input[type=text], input[type=number] {
      height: 30px;
      font-size: 12px;
      padding: 5px 8px 5px 8px;
    }
    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    /* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
      width: 60px !important;
    }
    .textAreaInput {
      .form-control {
        font-size: 12px;
        width: 180px;
      }
    }
    .smallInput {
      input[type=text], input[type=number] {
        height: 30px;
        width: 60px;
        font-size: 12px;
        padding: 5px 8px 5px 8px;
      }
    }
  }
  .button {
    color: $white;
    font-weight: 700;
    border-radius: 5px;
    outline: none;
    font-size: 12px;
    position: relative;
    padding: 4px 15px 4px 15px;
    box-shadow: none;
    &.find{
      background: #f56f62;
      border: 1px solid #f56f62;
    }
  }
  .bigWrapper {
    box-shadow: 0 0px 10px rgba(0,0,0,0.15);
    transition: box-shadow 0.3s ease-in-out;
    border-radius: 5px;
    border: 1px solid #ddd;
    width: 100%;
  }
}